export const gtmTrack = (
  eventTitle,
  eventCategory,
  eventAction,
  eventLabel
) => {
  console.log("%c event: ", "color: red", eventTitle)
  console.log("%c category: ", "color: red", eventCategory)
  console.log("%c action: ", "color: red", eventAction)
  console.log("%c label: ", "color: red", eventLabel)
  console.log("%c -----------: ", "color: blue")

  window.dataLayer.push({
    event: eventTitle,
    eventCategory,
    eventAction,
    eventLabel,
  })
}

export const gtmTrackVideo = (
  interactionName,
  currentPercentage,
  currentTime,
  videoDuration,
  videoTitle,
  videoId,
  videoUrl
) => {
  // category: Video
  // action: Start - [Video Name] / Watch to 10% - [Video Name]
  // label: [Video File Path] // do this in gtm

  // console.log("%c event: ", "color: red", 'videoTrack')
  // console.log("%c category: ", "color: red", "Video")
  // console.log("%c action: ", "color: red", interactionName + " - " + videoTitle)
  // console.log("%c videoName: ", "color: red", videoTitle)
  // console.log("%c videoTitle: ", "color: red", videoTitle)
  // console.log("%c videoDuration: ", "color: red", videoDuration)
  // console.log("%c videoId: ", "color: red", videoId)
  // console.log("%c videoPercentage: ", "color: red", currentPercentage + "%")
  // console.log("%c videoLapsedTime: ", "color: red", currentTime + "seconds")
  // console.log("%c -----------: ", "color: blue")
  let videoCustomMatric = interactionName === "Start" ? 1 : 12
  if (interactionName.includes("%")) {
    videoCustomMatric = currentPercentage / 10 + 1
  }

  window.dataLayer.push({
    event: "videoTrack",
    eventCategory: "Video",
    eventAction: interactionName + " - " + videoTitle,
    eventLabel: videoUrl,
    attributes: {
      videoName: videoTitle,
      videoTitle: videoTitle,
      videoDuration: Math.round(videoDuration),
      videoId: videoId,
      videoPercentage: currentPercentage + "%",
      videoLapsedTime: Math.round(currentTime),
      videoCustomMatric: videoCustomMatric,
    },
  })
}

export const gtmSubmit = formName => {
  //2.1.14
  // Category – Form
  // Action – $Form Name - $Button Text (or Submit)
  // Label –  (No Label needed assuming the following success page has a unique success URL, tracked as a GA pageview.)
  gtmTrack("formSubmit", "Form", formName + " - submit", null)
}

export const gtmTrackFormInteractions = (formName, formClass) => {
  //2.1.12
  // Category – Form
  // Action – $Form Name – $Field Name
  // Label – Interaction  (no value collected, only an indication of an interaction)

  var form = document.querySelector("." + formClass)

  var onFieldInteract = input => {
    var fieldValue = input.checked || input.value
    var fieldName = input.name

    if (formName === "XYWAV IH SEND RESULTS") {
      if (fieldName === "consent") {
        fieldName = "optional CRM optin"
      }
      if (fieldName === "xywavUsage" || fieldName === "diagnosis") {
        fieldName = fieldName + " - " + input.value
      }
    }

    if (fieldName && fieldValue) {
      gtmTrack(
        "formInteraction",
        "Form",
        formName + " - " + fieldName,
        "Interaction"
      )
    }
  }

  if (form) {
    var el_inputs = form.querySelectorAll(
      "input[name='email'], input[type=text], input[type=file], textarea, select"
    )

    el_inputs.forEach(input => {
      input.addEventListener("blur", () => onFieldInteract(input), true)
    })

    var el_clickables = form.querySelectorAll(
      "input[type=radio], input[type=checkbox]"
    )

    el_clickables.forEach(input => {
      input.addEventListener("click", () => onFieldInteract(input), true)
    })
  }
}

export const gtmTrackFormErrors = (formName, errors) => {
  // 3.2
  // Category: Form Error
  // Action: Fields
  // Label: [Error Message1, Error Message 2, Error Message 3, etc.]  (red text)
  const errorMessages = Object.keys(errors).join(", ")
  gtmTrack("formError", "FormError", formName, errorMessages)
}

export const gtmTrackVideoProgress = (
  progress,
  everyXPercent,
  trackedPercent,
  videoTitle,
  duration,
  videoId,
  videoUrl
) => {
  const currentPercentage = Math.floor((progress.played * 100) / 10) * 10
  const currentSeconds = Math.floor(progress.playedSeconds)

  if (
    currentPercentage % everyXPercent === 0 &&
    currentPercentage > 0 &&
    !trackedPercent.includes(currentPercentage)
  ) {
    gtmTrackVideo(
      "Watch to " + currentPercentage + "%",
      currentPercentage,
      currentSeconds,
      duration,
      videoTitle,
      videoId,
      videoUrl
    )
    trackedPercent.push(currentPercentage)
  }
}

export const getGA4ClientId = () => {
  // Get the GA4 client ID from the cookies "_ga"
  const cookies = document.cookie.split("; ")
  const gaCookie = cookies.find(cookie => cookie.startsWith("_ga="))

  if (gaCookie) {
    // Extract the GA4 client ID and remove the GA1.2. prefix
    return gaCookie.split("=")[1].replace(/^GA\d+\.\d+\./, "")
  }

  return null
}

export const getGA4MeasurementId = () => {
  window.google_tag_manager = window.google_tag_manager || {}

  // Get the GA4 measurement ID
  const measurementId = Object.keys(window.google_tag_manager).find(key =>
    key.startsWith("G-")
  )

  return measurementId || null
}
