import React from "react"
import { AppProvider } from "@components/context"
import "./src/styles/global.css"
import { gtmTrack } from "./src/components/utilsGTM"

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
)

let pageTimer
let counter = 0

const timers = [
  { time: 15000, label: "15s" },
  { time: 30000, label: "30s" },
  { time: 60000, label: "1min" },
  { time: 120000, label: "2min" },
  { time: 300000, label: "5min" },
  { time: 600000, label: "10min" },
]

const initPageTimer = () => {
  pageTimer = setInterval(() => {
    counter++

    timers.forEach(timer => {
      if (timer.time / 15000 === counter) {
        gtmTrack("pageTimer", "User Engagement", "Time Spent", timer.label)
      }
    })
  }, 15000)
}

export const onRouteUpdate = () => {
  clearInterval(pageTimer)
  counter = 0
  initPageTimer()
}
